import React from 'react';

import Link from 'utils/localized-link';

import ProductsFeatured from 'components/sections/products-featured';
import ProductsHeader from 'components/sections/products-header';
import Banner from 'components/sections/banner';
import Layout from 'components/layout';
import SEO from 'components/seo';


const WEB_APP_URL = process.env.GATSBY_WEB_APP_URL;

const bannerImage = 'create-account.png';

const bannerButtons = [
  {
    external: true,
    linkProps: {
      to: `${WEB_APP_URL}/register`
    },
    light: true,
    noArrow: true,
    small: true,
    text: 'about.banner.buttons.sign_up'
  },

  {
    linkComponent: Link,
    linkProps: {
      to: '/contacts'
    },
    light: true,
    noArrow: true,
    noBackground: true,
    small: true,
    text: 'about.banner.buttons.contacts'
  }
];

const ProductsPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title="products.title" />
    <ProductsHeader />
    <ProductsFeatured />
    <Banner
      buttons={bannerButtons}
      image={bannerImage}
      text="about.banner.text"
      title="about.banner.title"
    />
  </Layout>
);

export default ProductsPage;
