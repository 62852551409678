import React from 'react';
import { FormattedMessage } from 'react-intl';

import Link from 'utils/localized-link';

import Button from '@youship/components/objects/button';

import './styles.scss';


const ProductsHeader = () => (
  <div className="products-header">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col col-12 col-md-7">
          <div className="products-header__title-container">
            <div className="products-header__title-wrapper">
              <h1 className="h1--display products-header__title">
                <FormattedMessage id="products.header.title" />
              </h1>
            </div>
          </div>
          <div className="products-header__description">
            <FormattedMessage id="products.header.subtitle" />
          </div>
          <Button
            context="primary"
            linkComponent={Link}
            linkProps={{ to: '/contacts' }}
            noBackground
            noPadding
            noShadow
            small
          >
            <FormattedMessage id="products.header.contact" />
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default ProductsHeader;
