import React from 'react';

import Featured from 'components/featured';

import './styles.scss';

const featuredImage1 = 'about/image_1.jpg';
const featuredImage2 = 'about/image_2.jpg';
const featuredImage3 = 'about/image_3.jpg';
const featuredRobot = 'products/robot.png';

const ProductsFeatured = () => (
  <div className="products-featured">
    <div className="container-md products-featured__container">
      <a name="customer" id="customer" className="products-featured__featured-anchor">&nbsp;</a>
      <Featured
        classNames="products-featured__featured-row"
        description="products.featured.f1.description"
        image={featuredImage1}
        title="products.featured.f1.title"
      />
      <a name="courier" id="courier" className="products-featured__featured-anchor">&nbsp;</a>
      <Featured
        classNames="products-featured__featured-row"
        description="products.featured.f2.description"
        image={featuredImage2}
        invert
        title="products.featured.f2.title"
      />
      <a name="drive" id="drive" className="products-featured__featured-anchor">&nbsp;</a>
      <Featured
        classNames="products-featured__featured-row"
        description="products.featured.f3.description"
        image={featuredImage3}
        title="products.featured.f3.title"
      />
      <a name="store" id="store" className="products-featured__featured-anchor">&nbsp;</a>
      <Featured
        classNames="products-featured__featured-row"
        description="products.featured.f4.description"
        image={featuredImage2}
        invert
        title="products.featured.f4.title"
      />
      <a name="robot" id="robot" className="products-featured__featured-anchor">&nbsp;</a>
      <Featured
        classNames="products-featured__featured-row"
        description="products.featured.f5.description"
        image={featuredRobot}
        title="products.featured.f5.title"
      />
    </div>
  </div>
);

export default ProductsFeatured;
